export class Result {
  prenom: string;
  nom: string;
  entreprise: string;
  // email: string;
  nomFormateur: string;
  date: string;

  apportStage: string;
  outilsPresentes: string;
  ennui: string;
  reponseQuestions: string;
  implicationAnimateur: string;
  explicationManager: string;
  thematiquesTraitees: string;
  ifNotThematiquesTraitees: string;
  commentaire: string;
  aideSeminaire: string;
  suggestionAmelioration: string;
}
