import { Injectable } from "@angular/core";
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Data } from '@angular/router';
// import { config } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Result } from "./result.model";
import { NomFormateur } from "./nomFormateur";

@Injectable()
export class ServiceApp {

    constructor(private http: HttpClient) {}

    getAll(): Observable<any> {
        return this.http.get<Result[]>(`https://solareval.fr/api/results`);
    }

    getUserByName(name: string) {
        const params = new HttpParams()
        .set('entreprise', name.toString());
        return this.http.get<Result[]>(`https://solareval.fr/api/byName`, { params });
    }

    getUserByDate(date: Date) {
        const params = new HttpParams()
        .set('date', date.toString());
        return this.http.get<Result[]>(`https://solareval.fr/api/byDate`, { params });
    }

    getResultByDateAndName(date: string, name: string) {
        const params = new HttpParams()
        .set('date', date)
        .set('entreprise', name);
        console.log(params);
        return this.http.get<Result[]>(`https://solareval.fr/api/byDateAndName`, { params });
    }

    getResultByDateAndNameFormateur(date: string, nameFormateur: string) {
        const params = new HttpParams()
        .set('date', date)
        .set('nomFormateur', nameFormateur);
        console.log(params);
        return this.http.get<Result[]>(`https://solareval.fr/api/byDateAndNameFormateur`, { params });
    }

    getUserByNameAndNameFormateur(nameFormateur: string, name: string) {
        const params = new HttpParams()
        .set('nomFormateur', nameFormateur.toString())
        .set('entreprise', name.toString());
        return this.http.get<Result[]>(`https://solareval.fr/api/byNameAndNameFormateur`, { params });
    }

    getResultByNameFormateur(nameFormateur: string) {
        const params = new HttpParams()
        .set('nomFormateur', nameFormateur)
        return this.http.get<Result[]>(`https://solareval.fr/api/byNameFormateur`, { params });
    }

    getResultBetweenTwoDates(firstDate: string, secondDate: string) {
        const params = new HttpParams()
        .set('firstDate', firstDate)
        .set('secondDate', secondDate)
        return this.http.get<Result[]>(`https://solareval.fr/api/betweenTwoDates`, { params });
    }

    getResultBetweenTwoDatesAndFormateurName(firstDate: string, secondDate: string, nomFormateur: string) {
        const params = new HttpParams()
        .set('firstDate', firstDate)
        .set('secondDate', secondDate)
        .set('nomFormateur', nomFormateur)
        return this.http.get<Result[]>(`https://solareval.fr/api/betweenTwoDatesAndNomFormateur`, { params });
    }

    createResult(result: Result) {
        console.log(result);
        return this.http.post<Result>(`https://solareval.fr/api/create`, { result });
    }

    getAllFormateurs(): Observable<any> {
        return this.http.get<NomFormateur[]>(`https://solareval.fr/api/formateurs`);
    }    

    createNomFormateur(nomFormateur: NomFormateur) {
        return this.http.post<NomFormateur>(`https://solareval.fr/api/createFormateur`, { nomFormateur });
    }
}