import { Component, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators, SelectMultipleControlValueAccessor } from '@angular/forms';
import { Result } from './result.model';
import { ExcelService } from './excel.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ServiceApp } from './app.service';
import { InputsModule, ButtonsModule } from 'angular-bootstrap-md'
import { BigResult } from './bigResult';
import { NomFormateur } from './nomFormateur';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'solar-application';
  responseSended: boolean = false;

  coordonneeForm: FormGroup;
  satisfactionForm: FormGroup;
  restrictedForm: FormGroup;
  exportForm: FormGroup;

  nomsFormateur: string[] = [];

  selectedEntry: Result;

  printArea: boolean = true;
  afficheMsgErreur: boolean = false;

  usersNameResult: Result[] = [];

  finalData: any[] = [];
  finalResult: Result;
  bigResults: BigResult[] = [];
  bigData: Result[] = [];
  data: any[] = [];
  selectedDates: Date[] = [];

  triggerAdminConsole: boolean = false;
  foundIt: boolean = false;

  options = [];

  iconEn: string;
  iconFr: string;

  constructor(private excelService: ExcelService, private appService: ServiceApp, private translate: TranslateService) {

    // set default language
    translate.setDefaultLang('fr');
    this.iconEn = 'icon-en';
    this.iconFr = 'fr-brightness';

    this.responseSended = false;
    this.coordonneeForm = new FormGroup({
      prenom: new FormControl('', [Validators.required]),
      nom: new FormControl('', Validators.required),
      entreprise: new FormControl('', [Validators.required]),
      nomFormateurs: new FormControl('', Validators.required),
    });

    this.restrictedForm = new FormGroup({
      password: new FormControl('')
    });

    this.exportForm = new FormGroup({
      byDate: new FormControl(''),
      byName: new FormControl(''),
      byFormateurName: new FormControl(''),
      dates: new FormControl(''),
      byFirstDate: new FormControl(''),
      bySecondDate: new FormControl('')
    })

    this.satisfactionForm = new FormGroup({
      apportStage: new FormControl('', [Validators.required]),
      outilsPresentes: new FormControl('', [Validators.required]),
      ennui: new FormControl('', [Validators.required]),
      reponseQuestions: new FormControl('', [Validators.required]),
      implicationAnimateur: new FormControl('', [Validators.required]),
      thematiquesTraitees: new FormControl('', [Validators.required]),
      ifNotThematiquesTraitees: new FormControl(''),
      explicationManager: new FormControl(''),
      commentaire: new FormControl('', [Validators.required]),
      aideSeminaire: new FormControl('', [Validators.required]),
      suggestionAmelioration: new FormControl('', [Validators.required]),
    });

    this.appService.getAllFormateurs().subscribe(result => {
      if (result) {
        for (let i = 0; result.length; i++) {
          this.nomsFormateur.push(result[i].nomFormateur)
        }
      }
    });
    this.nomsFormateur.push(" ");
  }

  onSubmit() {
    /* Verification du remplissage des champs */

    this.coordonneeForm.controls['prenom'].markAsTouched();
    this.coordonneeForm.controls['nom'].markAsTouched();
    this.coordonneeForm.controls['entreprise'].markAsTouched();
    this.coordonneeForm.controls['nomFormateurs'].markAsTouched();

    this.satisfactionForm.controls['commentaire'].markAsTouched();
    this.satisfactionForm.controls['aideSeminaire'].markAsTouched();
    this.satisfactionForm.controls['suggestionAmelioration'].markAsTouched();

    if (this.satisfactionForm.status === 'INVALID') {
      this.afficheMsgErreur = true;
    }

    if (this.coordonneeForm.status === 'VALID' && this.satisfactionForm.status === 'VALID') {
      this.finalResult = new Result();

      this.afficheMsgErreur = false;

      this.finalResult.prenom = this.coordonneeForm.value.prenom;
      this.finalResult.nom = this.coordonneeForm.value.nom;
      this.finalResult.nomFormateur = this.coordonneeForm.value.nomFormateurs;
      this.finalResult.entreprise = this.coordonneeForm.value.entreprise;
      this.finalResult.date = new Date().toString();
      this.finalResult.apportStage = this.satisfactionForm.value.apportStage;
      this.finalResult.outilsPresentes = this.satisfactionForm.value.outilsPresentes;
      this.finalResult.ennui = this.satisfactionForm.value.ennui;
      this.finalResult.reponseQuestions = this.satisfactionForm.value.reponseQuestions;
      this.finalResult.implicationAnimateur = this.satisfactionForm.value.implicationAnimateur;
      this.finalResult.thematiquesTraitees = this.satisfactionForm.value.thematiquesTraitees;
      this.finalResult.ifNotThematiquesTraitees = this.satisfactionForm.value.ifNotThematiquesTraitees;
      if (this.satisfactionForm.value.explicationManager)
        this.finalResult.explicationManager = this.satisfactionForm.value.explicationManager;
      else
        this.finalResult.explicationManager = "";
      this.finalResult.commentaire = this.satisfactionForm.value.commentaire;
      this.finalResult.aideSeminaire = this.satisfactionForm.value.aideSeminaire;
      this.finalResult.suggestionAmelioration = this.satisfactionForm.value.suggestionAmelioration;

      this.responseSended = true;

      this.appService.createResult(this.finalResult).subscribe();
    }
  }

  isThematiquesTraiteesNon(): boolean {
    return this.satisfactionForm.get('thematiquesTraitees').value === 'Non';
  }

  get nom() {
    return this.coordonneeForm.get('nom');
  }
  get prenom() {
    return this.coordonneeForm.get('prenom');
  }
  get entreprise() {
    return this.coordonneeForm.get('entreprise');
  }
  get date() {
    return this.coordonneeForm.get('date');
  }
  get nomFormateurs() {
    return this.coordonneeForm.get('nomFormateurs');
  }
  get commentaire() {
    return this.coordonneeForm.get('commentaire');
  }
  get aideSeminaire() {
    return this.coordonneeForm.get('aideSeminaire');
  }
  get suggestionAmelioration() {
    return this.coordonneeForm.get('suggestionAmelioration');
  }

  exportAsXLSX(): void {
    if (this.exportForm.value.byFirstDate != null && this.exportForm.value.bySecondDate != null) {
      this.data = this.finalData;
      this.excelService.exportAsExcelFile(this.data, 'sample');
    }
  }

  onSubmitRestricted() {
    if (this.restrictedForm.status === 'VALID') {
      if (this.restrictedForm.value.password === 'steph007') {
        this.printArea = false;
      }
    }
  }

  exportByDate() {
    if (this.exportForm.value.byDate != null) {
      this.appService.getUserByDate(this.exportForm.value.byDate).subscribe(result => {
        this.usersNameResult = result;
      });
    }
  }

  exportByName() {
    if (this.exportForm.value.byFirstDate != null && this.exportForm.value.bySecondDate != null && (this.exportForm.value.byFormateurName === "" || this.exportForm.value.byFormateurName === " ")) {
      this.appService.getResultBetweenTwoDates(this.exportForm.value.byFirstDate, this.exportForm.value.bySecondDate).subscribe(result => {

        //  Fonction export PDF a retirer surement car informations néecssaires déjà ici a voir bug dans l'export pdf
        this.finalData = result;
        this.exportAsXLSX();
      });
     } else if (this.exportForm.value.byFirstDate != null && this.exportForm.value.bySecondDate != null && this.exportForm.value.byFormateurName != "") {
        this.appService.getResultBetweenTwoDatesAndFormateurName(this.exportForm.value.byFirstDate, this.exportForm.value.bySecondDate, this.exportForm.value.byFormateurName).subscribe(result => {
          //  Fonction export PDF a retirer surement car informations néecssaires déjà ici a voir bug dans l'export pdf
          this.finalData = result;
          this.exportAsXLSX();
        });
    }
  }

  createNewFormateur(nomFormateur: string) {
    let newNomFormateur = new NomFormateur();
    newNomFormateur.nomFormateur = nomFormateur;
    this.appService.createNomFormateur(newNomFormateur).subscribe(result => {
    });
    this.nomsFormateur.push(nomFormateur);
  }

  openAdminConsole() {
    this.triggerAdminConsole = true;
  }

  onSelectionChange(entry) {
    this.selectedEntry = entry;
  }

  useLanguage(language: string) {
    if (language === "en") {
      this.iconEn = "en-brightness";
      this.iconFr = "icon-fr"
    } else if (language === "fr") {
      this.iconFr = "fr-brightness";
      this.iconEn = "icon-en"
    }
    this.translate.use(language);
  }
}
